import React, { Component } from 'react'
import axios from 'axios'
import ReactPlayer from 'react-player'

import "./styles/w3.css";
import './styles/sustainability.css'

class Sustainability extends Component {

  constructor(props) {
    super(props)

    this.state = {
      sustainabilityTitle: '',
      sustainabilityContent: '',
      secondContentBlock: '',
      pageVideo: '',
      biocelNavLinkClass: ['active-biocel', ''],
      biocelType: 'laminate'
    }
    this.showHide = this.showHide.bind(this)
  }

  componentWillMount() {
    axios.get('/wp-json/wp/v2/pages?slug=sustainability-page2')
      .then(res => {
        this.setState({
          sustainabilityTitle: res.data[0].title.rendered,
          sustainabilityContent: res.data[0].content.rendered,
          secondContentBlock: res.data[0].acf.second_content_block,
          pageVideo: res.data[0].acf.page_video
        }, () => console.log('pageContent', this.state.aboutUsContent))
      })
      .catch(errors => console.log(errors))
  }

  showHide(num) {
    if (num === 0) {
      this.setState({
        biocelNavLinkClass: ['active-biocel', '', ''],
        biocelType: 'laminate'
      })
    }
    if (num === 1) {
      this.setState({
        biocelNavLinkClass: ['','active-biocel', ''],
        biocelType: 'cushion'
      })
    }
    if (num === 2) {
      this.setState({
        biocelNavLinkClass: ['','','active-biocel'],
        biocelType: 'envirocel'
      })
    }
  }

  render() {
    const { sustainabilityTitle, sustainabilityContent } = this.state
    return (
      <div className="sustainability-wrapper">
        <div className="sustainability-header">
          <div className="sustainability-header-content">
              <h2>Sustainability</h2>
          </div>
        </div>
        <div className="sustainability-above-fold">
          {/* <h3 dangerouslySetInnerHTML={{__html: sustainabilityTitle}} className="sustainability-above-title" /> */}
		  {/* <div dangerouslySetInnerHTML={{__html: sustainabilityContent}} className="sustainability-above-content" /> */}
		  <div className="sustainability-above-content">
		  At Signature Flooring, our mission extends beyond crafting exceptional products, it’s centered on being a conscientious member of our community, making positive contributions to society,
		  and continually progressing on our journey toward comprehensive sustainability. Engaged is an ongoing quest for improvement, we continue to refine our company-wide practices,
		  strengthen certifications, and earnestly strive for healthier environments.
		  </div>
        </div>
		
		<div className="sustainability-wrapper-2" style={{ backgroundImage: 'url("/wp-content/uploads/2024/03/SustainabilityBackgroundPanel_1280x500.jpg")' }}>
			
			<div className="w3-row sustainability-programs-section">
			
				<div className="w3-center">
				
					<div className="w3-col l3 s12 w3-display-container">
						<h1 className="sustainability-wrapper-2-text-1"></h1>
						<a href="https://designforhealth.mindclick.com/vendors/signature" target="_blank" rel="noopener noreferrer">
						<img src="/wp-content/uploads/2024/03/MindClick_Logo_273x227.png" alt="MindClick" className="w3-image"/>
						</a>
						<p className="w3-center sustainability-wrapper-2-text-2">MindClick Sustainability<br/>Assessment Program (MSAP)</p>

						<a href="https://designforhealth.mindclick.com/vendors/signature" target="_blank" rel="noopener noreferrer">
						<button className="w3-button w3-white w3-round-large sustainability-wrapper-2-text-3">View Signature & Ratings</button>
						</a>
										
					</div>	
					
					<div className="w3-col l3 s12 w3-display-container sustainability-container-2">
						<h1 className="sustainability-wrapper-2-text-1"></h1>
						<a href="https://carpet-rug.org/glp-carpets/" target="_blank" rel="noopener noreferrer">
						<img src="/wp-content/uploads/2024/03/CRI_Logo_273x227.png" alt="CRI" className="w3-image"/>
						</a>
						<p className="w3-center sustainability-wrapper-2-text-2">CRI Green Label Plus<br/>Carpet Indoor Air Quality</p>
						<a href="https://carpet-rug.org/glp-carpets/" target="_blank" rel="noopener noreferrer">
						<button className="w3-button w3-white w3-round-large sustainability-wrapper-2-text-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View Certifications&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
						</a>						
					</div>

					<div className="w3-col l3 s12 w3-display-container sustainability-container-2">
						<h1 className="sustainability-wrapper-2-text-1"></h1>
						<a href="https://www.scsglobalservices.com/certified-green-products-guide?manuf=42926" target="_blank" rel="noopener noreferrer">						
						<img src="/wp-content/uploads/2024/03/FloorScore_Logo_273x227.png" alt="FloorScore" className="w3-image"/>
						</a>
						<p className="w3-center sustainability-wrapper-2-text-2">FloorScore Hard Surface<br/>Indoor Air Quality</p>
						<a href="https://www.scsglobalservices.com/certified-green-products-guide?manuf=42926" target="_blank" rel="noopener noreferrer">
						<button className="w3-button w3-white w3-round-large sustainability-wrapper-2-text-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View Certifications&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
						</a>						
					</div>

					<div className="w3-col l3 s12 w3-display-container sustainability-container-2">
						<h1 className="sustainability-wrapper-2-text-1"></h1>
						<a href="/wp-content/uploads/2019/03/BioCel-Brochure.pdf" target="_blank" rel="noopener noreferrer">						
						<img src="/wp-content/uploads/2024/03/FarmToFloor_273x227.jpg" alt="Farm To Floor" className="w3-image"/>
						</a>
						<p className="w3-center sustainability-wrapper-2-text-2">Renewable Biobased<br/>Backing Systems</p>
						<a href="/wp-content/uploads/2019/03/BioCel-Brochure.pdf" target="_blank" rel="noopener noreferrer">
						<button className="w3-button w3-white w3-round-large sustainability-wrapper-2-text-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;View Brochure&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
						</a>						
					</div>					

				</div>
			
			</div>

        </div>

		{/* Begin MindClick Section */}
		<div className="sustainability-hero" style={{ backgroundImage: 'url("/wp-content/uploads/2024/03/MindClickHero-_With-Badge_1280x500.jpg")' }}>
			<div className="sustainability-hero-content">
			</div>
		</div>
	  
		<div className="sustainability-marketing-block">
			<div className="smb-graphic">
				<a href="https://designforhealth.mindclick.com/vendors/signature" target="_new">
					<img src="/wp-content/uploads/2024/03/MindClick_452x380.jpg" alt="MindClick" height="300px" />
				</a>
			</div>
			<div className="smb-text" style={{ paddingTop: "34px" }}>
			  <p className="bold-text">MindClick Sustainability Assessment Program (MSAP)</p>
			  <p className="sustainability-paragraph-block">
			  The MindClick Sustainability Assessment program (MSAP) measures Environmental and Social (ESG) impacts throughout the product life cycle, providing good,
			  better and best ratings using a 200 point scale based on 9 metrics: Raw Materials, Chemicals, Manufacturing – Environmental, Manufacturing – Social, Packaging, Distribution, Hotel Impact, Guest Health
			  and End of Use. Our partnership with MSAP allows us to continue forward with our journey towards environmental and social programs.
			  </p>

				<div className="buttons-wrapper">            
					<div className="design-button">
						<a
						  href="https://designforhealth.mindclick.com/vendors/signature"
						  target="_new"
						  rel="noopener noreferrer"
						>
						  <button className="design-row-button-large">
							View Signature & Ratings
						  </button>
						</a>
						&nbsp;&nbsp;
					</div>
				</div>
			
			</div>
		</div>
		{/* End MindClick Section */}

		{/* Begin CRI Section */}
		<div className="sustainability-hero" style={{ backgroundImage: 'url("/wp-content/uploads/2024/03/CRI_GreenLabelPlus_Hero_1280x500.jpg")' }}>
			<div className="sustainability-hero-content">
			</div>
		</div>
	  
		<div className="sustainability-marketing-block">
			<div className="smb-graphic sustainability-CRI-image">
				<a href="https://carpet-rug.org/glp-carpets/" target="_new">
					<img src="/wp-content/uploads/2024/02/CRI_TerraCrudaCPTILE_452x380.jpg" alt="CRI" height="300px" />
				</a>
			</div>
			<div className="smb-text" style={{ paddingTop: "34px" }}>
			  <p className="bold-text">CRI Green Label Plus Indoor Air Quality</p>
			  <p className="sustainability-paragraph-block">
				Green Label Plus meets, and exceeds, California’s indoor quality standards for low-emitting products used in commercial settings.
				The carpet industry took the initiative to enhance its Green Label program for carpet, adhesives and cushion to meet testing protocols used by the Collaborative for High Performance Schools (CHPS).
				Under the agreement between CRI and the California agencies, Green Label Plus is acceptable for the CHPS low-emitting materials criteria.
				<br/><br/>The Green Label Plus symbol indicates:
				<div className="sustainability-CRI-list">
				<li>Signature voluntarily participates in the program and is committed to developing ways to minimize any adverse effects on indoor air quality.</li> 
				<li>A representative sample of the product type is tested by an independent laboratory and meets the established emissions requirements.</li>  
				<li>The product is also recognized by the US Green Building Council’s LEED version 4 standard as a Low-Emitting Materials Third Party Certification and can contribute one point to a building’s LEED score.</li> 
				</div>
			  </p>
				<div className="buttons-wrapper">            
					<div className="design-button">
						<a
						  href="https://carpet-rug.org/glp-carpets/"
						  target="_new"
						  rel="noopener noreferrer"
						>
						  <button className="design-row-button-large" style={{ width: "350px" }}>
							Search to download Signature's Certificates
						  </button>
						</a>
						&nbsp;&nbsp;
					</div>
				</div>        
			</div>
		</div>
		{/* End CRI Section */}

		{/* Begin FloorScore Section */}
		<div className="sustainability-hero" style={{ backgroundImage: 'url("/wp-content/uploads/2024/03/FloorScoreHero_LocalConnections_1280x500.jpg")' }}>
			<div className="sustainability-hero-content">
			</div>
		</div>
	  
		<div className="sustainability-marketing-block">
			<div className="smb-graphic">
				<a href="https://www.scsglobalservices.com/certified-green-products-guide?manuf=42926" target="_new">
					<img src="/wp-content/uploads/2024/02/VividExpressions_452x380.jpg" alt="CRI" height="300px" />
				</a>
			</div>
			<div className="smb-text" style={{ paddingTop: "34px" }}>
			  <p className="bold-text">FloorScore&reg;</p>
			  <p className="sustainability-paragraph-block">
				FloorScore&reg; is the most recognized indoor air quality (IAQ) certification standard for hard surface flooring materials, adhesives, and underlayments.
				Developed by SCS with the&nbsp;
				<a href="https://rfci.com/" target="_new" className="sustainability-text-url" style={{ color: "#A28D5B" }}>Resilient Floor Covering Institute (RFCI)</a>
				, a leading industry trade association of flooring manufacturers and suppliers,
				it qualifies for many green schemes including LEED v4.1, WELL, BREEAM, CHPS, and Green Globes. 
			  </p>
				<div className="buttons-wrapper">            
					<div className="design-button">
						<a
						  href="https://www.scsglobalservices.com/certified-green-products-guide?manuf=42926"
						  target="_new"
						  rel="noopener noreferrer"
						>
						  <button className="design-row-button-large" style={{ width: "350px" }}>
							Find us under the Green Products Guide
						  </button>
						</a>
						&nbsp;&nbsp;
					</div>
				</div>        
			</div>
		</div>
		{/* End FloorScore Section */}
		
		{/* Begin Farm To Floor Section */}
		<div className="sustainability-hero" style={{ backgroundImage: 'url("/wp-content/uploads/2024/03/FarmToFloor_Hero_1280x500.jpg")' }}>
			<div className="sustainability-hero-content">
			</div>
		</div>
		
		<div className="sustainability-marketing-block">
			<div className="smb-graphic" style={{ paddingTop: "40px" }}>
				<a href="https://www.youtube.com/watch?v=4WQJAh-zJv8" target="_new">
					<img src="/wp-content/uploads/2024/03/Bainbridge-Family-Video.jpg" alt="Renewable Biobased Backing Systems" height="300px" />
				</a>
			</div>
			<div className="smb-text" style={{ paddingTop: "34px" }}>
			  <p className="bold-text">Renewable Biobased Backing Systems</p>
			  <p className="sustainability-paragraph-block">
				BioCel&trade; and EnviroCel&trade; are the carpet backing solutions for thoughtfully designed spaces. BioCel and EnviroCel are environmentally responsible, high-performance attached backing systems
				replacing a percentage of the petroleum-based polymers with renewable, biobased material extracted from U.S.-grown soybeans. 
				<br/><br/>BioCel and EnviroCel meet the requirements for purchasing carpet with biobased content under the U.S. Department of Agriculture’s BioPreferred&reg; Program SIN 31-601 and is NSF/ANSI 140 compliant ready.
				Carpet backed with BioCel and EnviroCel also assist in meeting the Federal Government’s objective to create U.S. jobs through its purchase of products made with materials from U.S.-grown farmers. 
				<br/><br/>US Department of Agriculture’s BioPreferred&trade; Program SIN 31-601 NSF/ANSI 140 compliant. 
			  </p>      
			</div>
		</div>
	  
		<div className="sustainability-marketing-block">
			<div className="smb-graphic">
				<a href="/wp-content/uploads/2020/07/PentagonEnviroCelCaseStudy.pdf" target="_new">
					<img src="/wp-content/uploads/2024/02/US_Pentagon_452x380.jpg" alt="CRI" height="300px" />
				</a>
			</div>
			<div className="smb-text" style={{ paddingTop: "0px" }}>
			  <p className="bold-text">Pentagon Uses Signature’s EnviroCel</p>
			  <p className="sustainability-paragraph-block">
				The Pentagon requirements for entry mats include a three-fold test: performance, sustainability and cost effectiveness.
				Since the pilot in 2010, the Pentagon has used Signature’s EnviroCel soy-backed mats for its main entrances.
				Use of the entry mats supports the Presidential Executive Order: Planning for Federal Sustainability in the Next Decade (EO 13693).
				Issued in March 2015, the EO complements the laws that Congress enacted for the federal government to set the pace for the nation by buying biobased products, such as those made with soy. 
				<br/><br/>Signature’s Trellis Entryway System is available with EnviroCel Sustainable Backing, contact your local Signature sales representative for more information. 
			  </p>
				<div className="buttons-wrapper">            
						<div className="design-button">
							<a
							  href="https://www.youtube.com/watch?v=4WQJAh-zJv8"
							  target="_new"
							  rel="noopener noreferrer"
							>
							  <button className="design-row-button-large" style={{ width: "300px" }}>
								View Bainbridge Family Story
							  </button>
							</a>
							&nbsp;&nbsp;						
							<a
							  href="/wp-content/uploads/2019/03/BioCel-Brochure.pdf"
							  target="_new"
							  rel="noopener noreferrer"
							>
							  <button className="design-row-button-large" style={{ width: "300px" }}>
								Download BioCel/EnviroCel Brochure
							  </button>
							</a>
							&nbsp;&nbsp;
						</div>
					</div> 			  
				</div> 
		</div>
		{/* End Farm To Floor Section */}

		{/* Begin Made In the USA Section */}
		<div className="sustainability-hero" style={{ backgroundImage: 'url("/wp-content/uploads/2024/03/MadeInUSA_LocalConnectionsHero_1280x500.jpg")' }}>
			<div className="sustainability-hero-content">
			</div>
		</div>
	  
		<div className="sustainability-marketing-block">
			<div className="smb-graphic">
				<img src="/wp-content/uploads/2024/02/UrbanEscapes_452x380.jpg" alt="CRI" height="300px" />
			</div>
			<div className="smb-text" style={{ paddingTop: "34px" }}>
			  <p className="bold-text">SPC LVT Made in the USA</p>
			  <p className="sustainability-paragraph-block">
				Signature SPC products are made exclusively in the United States, offering clients unparalleled quality control, expedited shipping, and local connections that eliminate overseas shipping costs and tariffs.
				Utilizing proprietary design technologies, SPC flooring boasts advanced visual capabilities, remarkable color clarity, and realistic embossing and textures.
				With a commitment to environmental safety, products are phthalate-free and FloorScore® certified. Engineered for durability, the flooring features a dense, rigid composition, a 100% waterproof core,
				and a commercial ceramic urethane finish for stain, scuff, scratch, and wear resistance. Installation is made effortless with the innovative floating system,
				while attached cushioning provides acoustical benefits and a quieter underfoot experience. Moreover, Signature SPC ensures excellent lead times, superior customer service,
				and significant reduction in freight costs and processing. 
			  </p>
				<div className="buttons-wrapper">            
					<div className="design-button">
						<a
						  href="/wp-content/uploads/2022/09/SignatureFlooring_Local-Connections_Brochure.pdf"
						  target="_new"
						  rel="noopener noreferrer"
						>
						  <button className="design-row-button-large" style={{ width: "300px" }}>
							View Local Connections SPC Brochure
						  </button>
						</a>
						&nbsp;&nbsp;
						<a
						  href="/wp-content/uploads/2023/06/SIG354-UrbanEscape-broch-udpates_v4-1-FINAL.pdf"
						  target="_new"
						  rel="noopener noreferrer"
						>
						  <button className="design-row-button-large" style={{ width: "300px" }}>
							View Urban Escapes SPC Brochure
						  </button>
						</a>
						&nbsp;&nbsp;
					</div>
				</div>	
			</div>
		</div>
		{/* End Made In the USA Section */}

        
		{/*
		<div className="sustainability-video-block">
          <div className="sustainability-video-title">Meet the Bainbridge Family<p style={{fontSize: '16px', margin: '0 auto', textAlign: 'center'}}>Domestic Soybean Farmers</p></div>
          <div className="sustainability-video">
            <div className="sustainability-video-desktop">
              <ReactPlayer height="469px" width="754px" controls url="https://www.youtube.com/watch?v=4WQJAh-zJv8" />
            </div>
            <div className="sustainability-video-mobile">
              <ReactPlayer height="469px" width="100%" controls url="https://www.youtube.com/watch?v=4WQJAh-zJv8" />
            </div>
          </div>
          <div className="sustainability-video-content">
            <p>
            BioCel & EnviroCel meets the requirements for purchasing carpet with biobased content under the U.S. Department of Agriculture’s BioPreferred® Program SIN 31-601 and is NSF/ANSI 140 compliant ready. Carpet backed with BioCel and EnviroCel also assists in meeting the Federal Government’s objective to create U.S. jobs through its purchase of products made with materials from U.S.-grown farmers. 
            </p>
          </div>
        </div>


        <div className="biocel-content">
          <div className="biocel-content-wrapper">
            <div className="biocel-header">
              { this.state.biocelType === 'envirocel' &&
                <h2 dangerouslySetInnerHTML={{__html: 'EnviroCel™ Performance Backing System'}} />
              }
              { this.state.biocelType !== 'envirocel' &&
                <h2 dangerouslySetInnerHTML={{__html: 'BioCel™ Performance Backing System'}} />
              }
              
            </div>
            <div className="biocel-nav">
            <a href="##" onClick={() => this.showHide(0)} className={this.state.biocelNavLinkClass[0]}>BioCel™ Laminate</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="##" onClick={() => this.showHide(1)} className={this.state.biocelNavLinkClass[1]}>BioCel™ Cushion</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="##" onClick={() => this.showHide(2)} className={this.state.biocelNavLinkClass[2]}>EnviroCel™ </a>
            </div>
            { this.state.biocelType === 'laminate' &&
              <div className="biocel-laminate-wrapper">
                <div className="biocel-laminate-graphic-desktop">
                  <img src="/wp-content/uploads/2019/03/biocel-laminate-graphic.png" alt="Biocel Laminate" />
                </div>
                <div className="biocel-laminate-graphic-mobile">
                  <img src="/wp-content/uploads/2019/03/BioCel-Backing-Mobile.jpg" alt="Biocel Laminate" />
                </div>
                <div className="biocel-laminate-content">
                  <p>
                  Strength, flexibility and ease of installation make BioCel Cushion and BioCel Laminate the efficient, effective choice for hospitality and assisted living spaces. Each backing is designed to withstand repeated wet cleanings, allowing for each maintenance as well as protection against daily wear and tear. 
                  </p>
                </div>
                <div className="biocel-laminate-grid">
                  <div className="biocel-check-left">
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Recycled PET Secondary Backing</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Moisture Resistant</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Functional Liquid Barrier</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Great Lamination Strength</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Fully Recyclable</p>
                  </div>                  
                  <div className="biocel-check-right">
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Low VOC Rating</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Increased Appearance Retention</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Direct Glue Installation</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Tuft Bind Not Adversely Affected by Moisture</p>
                  </div>
                </div>
              </div>
            }
            { this.state.biocelType === 'cushion' && 
              <div className="biocel-cushion-wrapper">
                <div className="biocel-cushion-graphic-desktop">
                  <img src="/wp-content/uploads/2019/03/biocel-cushion-graphic.png" alt="Biocel Cushion" />
                </div>
                <div className="biocel-cushion-graphic-mobile">
                  <img src="/wp-content/uploads/2019/03/BioCel_Cushion-Mobile.jpg" alt="Biocel Cushion" />
                </div>
                <div className="biocel-laminate-content">
                  <p>
                  Strength, flexibility and ease of installation make BioCel Cushion and BioCel Laminate the efficient, effective choice for hospitality and assisted living spaces. Each backing is designed to withstand repeated wet cleanings, allowing for each maintenance as wells protection against daily wear and tear. 
                  </p>
                </div>
                <div className="biocel-laminate-grid">
                  <div className="biocel-check-left">
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Comfort Underfoot</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Reduces Muscle Fatigue</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Sound Insulating Properties</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Thermal Insulating</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Excellent Tuft-Bind</p>
                  </div>
                  
                  <div className="biocel-check-right">
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Superior Lamination Strength</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Fully Recyclable</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Ease of Installation</p>
                    <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Limited Lifetime Warranty</p>
                  </div>
                </div>
              </div>
            }

            {this.state.biocelType === 'envirocel' &&
            <div className="biocel-cushion-wrapper">
            <div className="biocel-cushion-graphic-desktop">
              <img src="/wp-content/uploads/2020/07/envirocel-graphic.jpg" alt="EnviroCel" /><br/>
            </div>
            <div className="biocel-cushion-graphic-mobile">
              <img src="/wp-content/uploads/2020/07/envirocel-graphic-mobile.jpg" alt="EnviroCel" />
            </div>
              <div className="biocel-laminate-content">
              <p>
              EnviroCel provides a high performance sustainable cushion backing system that replaces over 90% of petroleum-based polymers with renewable, bio-based material extracted from U.S.-grown soybeans. Stabilizing technology provides improved polyurethane backing, performance and stability, along with enhanced appearance retention and ergonomic benefits. EnviorCel is designed to withstand repeated wet cleanings, as well as protection against daily wear and tear.
              </p>
            </div>
            <div className="biocel-laminate-grid">
              <div className="biocel-check-left">
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Renewable, Bio-based Material</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Stabilizing Technology</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Ergonomic Benefits</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Enhanced Appearance Retention</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Contributes to LEED Credits</p>
              </div>
              <div className="biocel-check-right">
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Extended Product Lifecycle - Replacement Less Often</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Low VOC - CRI Certified</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Insulates against Sound Transfer and Engery Loss</p>
                <p><img src="/wp-content/uploads/2019/03/checkmark.jpg" alt="checkmark" /> Qualifies for Federal Purchasing under the USDA's BioPreferred Program</p>
              </div>
            </div>
            </div>
            }
          </div>
        </div>
		*/}


        {/** LEED */}
		{/*
        <div className="sustainability-leed">
          <div className="sustainability-leed-text">
            <h2>
              Learn more about Signature's BioCel Cushion, BioCel Laminate and EnviroCel sustainable and performing backing systems.
            </h2> 
        <span><a href="/wp-content/uploads/2019/03/BioCel-Brochure.pdf" target="_blank" className="sustainability-leed-button" dangerouslySetInnerHTML={{__html: 'BioCel Brochure'}} /></span>&nbsp;&nbsp;
        <span><a href="/wp-content/uploads/2020/07/EnviroCel-Brochure.pdf" target="_blank" className="sustainability-leed-button" dangerouslySetInnerHTML={{__html: 'EnviroCel Brochure'}} /></span>&nbsp;&nbsp;
        <span><a href="/productinfo/trellis/" className="sustainability-leed-button" dangerouslySetInnerHTML={{__html: 'EnviroCel Entryway System'}} /></span>&nbsp;&nbsp;
        <span><a href="/wp-content/uploads/2020/07/PentagonEnviroCelCaseStudy.pdf" target="_blank" className="sustainability-leed-button" dangerouslySetInnerHTML={{__html: 'EnviroCel / Pentagon Case Study'}} /></span>&nbsp;&nbsp;        
          </div>
        </div>
		*/}

        {/** NEED HELP? */}
		{/*
        <div className="sustainability-need-help">
          <div className="sustainability-need-help-text">
            <h2>Need Help?</h2> 
            <p>For more information or technical resources, please contact your sales representative or call us at 800-809-7086.</p>
            <a href="/salesreps" className="resources-find-rep-button">Find Your Signature Rep</a>&nbsp;&nbsp;
          </div>
        </div>
		8
		*/}
      </div>
    )
  }
}

export default Sustainability