import React, { Component } from "react";
import CustomEasy from "../Components/PageComponents/CustomEasy";
import axios from "axios";
import "./styles/segments.css";

const headerStyle = {
  width: "100%",
  height: "450px",
  background:
    'linear-gradient(to bottom, transparent 0%, black 175%), url("/wp-content/uploads/2019/02/Fireplace-2017-RS-Frame-4T-Web-1.jpg")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center"
};

const headerStyleMobile = {
  width: "100%",
  height: "450px",
  background:
    'linear-gradient(to bottom, transparent 0%, black 175%), url("/wp-content/uploads/2019/03/SIG_TerraCruda_Column_320_486.jpg")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center"
};

/*const segmentDesignTypes = {
  height: '400px',
  position: 'relative',
  background: 'linear-gradient(rgba(100,52,96,.9),rgba(100,52,96,.9)), url("/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg")',
  backgroundSize: 'cover',
  bottom:'0',
  top:'0'
}*/

const segmentDesignTypesMobile = {
  height: "100%",
  position: "relative",
  background:
    'linear-gradient(rgba(100,52,96,.9),rgba(100,52,96,.9)), url("/wp-content/uploads/2018/12/homepage-sigaccordbg.jpg")',
  backgroundSize: "cover",
  bottom: "0",
  top: "0"
};

const tradeShowsWrapper = {
  minHeight: "400px",
  background: "#643460"
};

class SegmentsHospitality extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segmentTitle: "",
      segmentContent: ""
    };
  }

  componentWillMount() {
    axios
      .get("/wp-json/wp/v2/segments")
      .then(res => {
        const result = res.data.find(
          title => title.title.rendered === "Hospitality"
        );
        if (result) {
          this.setState({
            segmentTitle: result.acf.content_title,
            segmentContent: result.acf.content_blurb
          });
        }
      })
      .catch(errors => console.log(errors));
  }

  render() {
    const { segmentContent, segmentTitle } = this.state;
    return (
      <div>
        <div className="segment-wrapper-desktop">
          <div className="hospitality-segment-header" style={headerStyle}>
            <div className="segment-header-content">
              <h2>Hospitality</h2>
            </div>
          </div>
          <div className="segemnt-above-fold">
            <h3>{segmentTitle}</h3>
            <p>{segmentContent}</p>
          </div>

          <div className="hosp-segment-design-types">
            <div className="design-rows">
              <div className="design-row">
                <div className="design-header">Collection Brochures</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                  Be inspired by the possibilities: explore stunning designs in
                  carpet and hard surface flooring, including an array of
                  customizable styles.
                </div>
                <div className="design-button"  style={{paddingTop: '35px'}}>
                  <a href="/customcollections">
                    <button className="design-row-button">
                      View Collections
                    </button>
                  </a>
                </div>
              </div>

              <div className="design-row">
                <div className="design-header">SignatureNOW</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                  A broadloom Carpet and LVT program with delivery in four weeks or less.<br/><br/><br/>
                </div>
                <div className="design-button"  style={{paddingTop: '35px'}}>
                  <a href="/now">
                    <button className="design-row-button">Find Out More</button>
                  </a>
                </div>
              </div>

              <div className="design-row">
                <div className="design-header">Signature Spark</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                  Time to play: use our intuitive design tool to recolor any
                  style, view styles in different room scenes and download your
                  customized flooring creations.
                </div>
                <div className="design-button"  style={{paddingTop: '10px'}}>
                  <a href="/signature-spark">
                    <button className="design-row-button-large">
                      Explore Signature Spark
                    </button>
                  </a>
                </div>
              </div>

              <div className="design-row">
                <div className="design-header">Branded Hotel Programs</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                Signature is a flooring industry leader focused soley on helping our clients create extraordinary hospitality and senior living spaces.
                </div>
                <div className="design-button"  style={{paddingTop: '35px'}}>
                  <a href="/branded-hotel-programs">
                    <button className="design-row-button-large">
                      View Branded Programs
                    </button>
                  </a>
                </div>
              </div>

              <div className="design-row">
                <div className="design-header">Project Installations</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                Signature is driven to achieve unique flooring solutions that expand each client's vision. See how we make signature spaces a reality.
                </div>
                <div className="design-button"  style={{paddingTop: '35px'}}>
                  <a href="/installations">
                    <button className="design-row-button-large">
                      View Projects
                    </button>
                  </a>
                </div>
              </div>

            </div>
          </div>

          <section className="segment-featured-styles-top">
            <div className="segment-featured-header">
              <p
                className="segment-featured-header-text"
                style={{ textAlign: "center" }}
              >
                Featured Styles
              </p>
            </div>
          </section>

          <section className="single-featured-row">
            <div className="single-featured-row product-block">
              <a href="https://spark.signatureflooring.com/products/?whatsnew=56" target="_blank"><img
                  src="/wp-content/uploads/2020/07/hospitality-destination-segment.jpg"
                  alt="featured"
                  style={{ width: "100%", height: "auto" }}
                />
                <div className="single-product-block-text">
                  <h2>Destination Spot</h2>
                </div>
                </a>
            </div>
          </section>

          <section className="featured-row">
            <div className="featured-row product-block">
              <a
                href="/hardsurface/LVT"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <img
                  src="/wp-content/uploads/2020/07/hospitality-nature-craft.jpg"
                  alt="featured"
                  style={{ width: "100%", maxWidth: "640px", height: "auto" }}
                />
                <div className="product-block-text">
                  <h2>Nature Craft</h2>
                  <p style={{ width: "95%" }}>
                    Clean, crisp and modern tones highlight the beautiful graining patterns of oak, maple and elm. 
                    Available in 12ml and 20ml contstructions and 7" x 48" plank size.
                  </p>
                </div>
              </a>
            </div>

            <div className="featured-row product-block">
              <a
                href="/productinfo/oxford"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <img
                  src="/wp-content/uploads/2020/07/hospitality-oxford-boucle.jpg"
                  alt="featured"
                  style={{ width: "100%", maxWidth: "640px", height: "auto" }}
                />
                <div className="product-block-text">
                  <h2>Oxford / Boucle</h2>
                  <p style={{ width: "95%" }}>
                    Reminisent of classically refined fabrics that have stood the test of time, Oxford & Boucle bring timeless aestic 
                    influences to design projects. Guest Rooms only. Ten additional custom paterns available.
                  </p>
                </div>
              </a>
            </div>
          </section>

          {/* <div className="trade-shows-wrapper" style={tradeShowsWrapper}>
          <div className="trade-shows-header">
            <span className="trade-shows-header-text">Trade Shows & Events</span>
          </div>
          <div className="trade-shows">
            <div className="trade-show-box1">
            <h2 style={{textAlign: 'left'}}>HD Expo 2019</h2>
              <span className="show-date" style={{textAlign: 'left'}}>May 15-17</span>
              <p>Bringing together designers, owner/operators, brand executives, architects, purchasers, and manufacturers for three days of inspiration, CEU-accredited education, and exceptional networking for the hospitality design community. Join Signature at booth 3027.</p>
            </div>
            <div className="trade-show-box-border">
              <hr />
            </div>
            <div className="trade-show-box2">
            <h2>BDNY 2019</h2>
              <span className="show-date">November 10-11</span>
              <p>Join Signature in New York City for BDNY 2019 at booth 3639. BDNY connects the most inventive manufacturers and the most influential design minds in the hospitality industry for two days of discovery, education and conversation. </p>
            </div>
          </div>
    </div> */}

          <CustomEasy />
        </div>

        <div className="segment-wrapper-mobile">
          <div
            className="hospitality-segment-header-mobile"
            style={headerStyleMobile}
          >
            <div className="segment-header-content-mobile">
              <h2>Hospitality</h2>
            </div>
          </div>
          <div className="segemnt-above-fold">
            <h3 style={{ paddingTop: "25px", paddingBottom: "15px" }}>
              {segmentTitle}
            </h3>
            <p style={{ paddingBottom: "25px" }}>{segmentContent}</p>
          </div>

          <div
            className="segment-design-types"
            style={segmentDesignTypesMobile}
          >
            <div className="design-rows">
              <div className="design-row">
                <div className="design-header">Collection Brochures</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                  Be inspired by the possibilities: explore stunning designs in
                  carpet and hard surface flooring, including an array of
                  customizable styles.
                </div>
                <div
                  className="design-button"
                  style={{ paddingBottom: "25px" }}
                >
                  <a href="/customcollections">
                    <button className="design-row-button">
                      View Collections
                    </button>
                  </a>
                </div>
              </div>

              <div className="design-row">
                <div className="design-header">SignatureNow</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                A broadloom Carpet and LVT program with delivery in four weeks or less.
                </div>
                <div
                  className="design-button"
                  style={{ paddingBottom: "25px" }}
                >
                  <a href="/customdesign">
                    <button className="design-row-button">Find Out More</button>
                  </a>
                </div>
              </div>

              <div className="design-row">
                <div className="design-header">Signature Spark</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                  Time to play: use our intuitive design tool to recolor any
                  style, view styles in different room scenes and download your
                  customized flooring creations.
                </div>
                <div
                  className="design-button"
                  style={{ paddingBottom: "25px" }}
                >
                  <a href="/signature-spark">
                    <button className="design-row-button-large">
                      Explore Signature Spark
                    </button>
                  </a>
                </div>
              </div>
              <div className="design-row">
                <div className="design-header">Branded Hotel Programs</div>
                <div className="design-hr">
                  <hr />
                </div>
                <div className="design-content">
                Signature is a flooring industry leader focused soley on helping our clients create extraordinary hospitality and senior living spaces.
                </div>
                <div className="design-button">
                  <a href="/branded-hotel-programs">
                    <button className="design-row-button-large">
                      View Branded Programs
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <section className="segment-featured-styles-top">
            <div className="segment-featured-header" style={{paddingTop: '0px'}}>
              <p
                className="segment-featured-header-text"
                style={{ textAlign: "center" }}
              >
                Featured Styles
              </p>
            </div>
          </section>

          <section className="featured-row-mobile">
            <div className="featured-row product-block">
                <img
                  src="/wp-content/uploads/2020/07/hospitality-destination-segment-top-sm.jpg"
                  alt="featured"
                  style={{ width: "100%", height: "auto" }}
                />
                <img
                  src="/wp-content/uploads/2020/07/hospitality-destination-segment-bottom-sm.jpg"
                  alt="featured"
                  style={{ width: "100%", height: "auto" }}
                />
                <div className="product-block-text">
                  <h2>Destination Spot</h2>
                </div>
            </div>

          </section>

          <section className="featured-row-mobile">
            <div className="featured-row-mobile product-block">
              <a
                href="/hardsurface/LVT"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <img
                  src="/wp-content/uploads/2020/07/hospitality-nature-craft.jpg"
                  alt="featured"
                  style={{ width: "100%", maxWidth: "414px", height: "auto"}}
                />
                <div className="product-block-text">
                  <h2>Nature Craft</h2>
                  <p style={{ width: "95%" }}>
                    Clean, crisp and modern tones highlight the beautiful graining patterns of oak, maple and elm. 
                    Available in 12ml and 20ml contstructions and 7" x 48" plank size.
                  </p>
                </div>
              </a>
            </div>

            <div className="featured-row-mobile product-block">
              <a
                href="/productinfo/oxford"
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <img
                  src="/wp-content/uploads/2020/07/hospitality-oxford-boucle.jpg"
                  alt="featured"
                  style={{ width: "100%", maxWidth: "414px", height: "auto" }}
                />
                <div className="product-block-text">
                  <h2>Oxford / Boucle</h2>
                  <p style={{ width: "95%" }}>
                  Reminisent of classically refined fabrics that have stood the test of time, Oxford & Boucle bring timeless aestic 
                    influences to design projects. Guest Rooms only. Ten additional custom paterns available.
                  </p>
                </div>
              </a>
            </div>
            
          </section>

          {/*<div className="trade-shows-wrapper-mobile" style={tradeShowsWrapper}>
          <div className="trade-shows-header">
            <span className="trade-shows-header-text">Trade Shows & Events</span>
          </div>
          <div className="trade-shows-mobile">
            <div className="trade-show-box">
            <h2 style={{fontFamily: 'Adelle Sans', fontSize: '20px', color: '#FFF'}}>HD Expo 2019</h2>
              <span className="show-date" style={{textAlign: 'left'}}>May 15-17</span>
              <p style={{marginRight: '10px', fontFamily: 'Adelle Sans', fontSize: '14px', opacity: '0.6', color: '#FFF'}}>Bringing together designers, owner/operators, brand executives, architects, purchasers, and manufacturers for three days of inspiration, CEU-accredited education, and exceptional networking for the hospitality design community. Join Signature at booth 3027.</p>
            </div>
            <div className="trade-show-box">
            <h2 style={{fontFamily: 'Adelle Sans', fontSize: '20px', color: '#FFF'}}>BDNY 2019</h2>
              <span className="show-date">November 10-11</span>
              <p style={{marginRight: '10px', fontFamily: 'Adelle Sans', fontSize: '14px', opacity: '0.6', color: '#FFF'}}>Join Signature in New York City for BDNY 2019 at booth 3639. BDNY connects the most inventive manufacturers and the most influential design minds in the hospitality industry for two days of discovery, education and conversation. </p>
            </div>
          </div>
  </div>*/}

          <CustomEasy />
        </div>
      </div>
    );
  }
}

export default SegmentsHospitality;
